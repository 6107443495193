import { Pipe, PipeTransform } from '@angular/core';
import { calculateCityTaxMaxRefundable } from '@app/helpers/calc-city-tax-max-refundable';
import { InvoiceRow } from '@app/models';

@Pipe({
  name: 'cityTaxAmount',
  standalone: true,
})
export class CityTaxAmountPipe implements PipeTransform {
  transform(bills: InvoiceRow[]): number {
    return calculateCityTaxMaxRefundable(bills);
  }
}
