<nz-table [nzData]="payments.controls" [nzShowPagination]="false">
  <thead>
    <tr>
      <th class="color--azure by-w-55">
        {{ 'payment' | translate | upperfirst }}
      </th>
      <th class="color--azure">
        {{ 'amount' | translate | upperfirst }}
      </th>
    </tr>
  </thead>
  <tbody>
    @for (
      payment of payments.controls;
      track payment.value.id;
      let index = $index
    ) {
      <tr>
        <td class="by-w-55 only-border-down">
          <div class="by-flex by-gap-5">
            <span>
              {{ payment.value.label }}
            </span>
            <span> ({{ payment.value.date | formatDate: 'L' }}) </span>
          </div>
        </td>
        <td class="only-border-down">
          <div class="by-flex by-gap-5 by-align-items--center by-pl-5">
            <i
              class="fas fa-arrow-alt-circle-right color--orange pointer"
              (click)="setMaxAmount(payment.value.id, index)"
            ></i>
            <nz-input-number
              (nzBlur)="onTouched()"
              [nzMin]="0"
              [nzMax]="maxAmountsMap[payment.value.id]"
              [nzStep]="1"
              [formControl]="payment.controls.currentAmount"
            ></nz-input-number>
            <span>
              {{ 'of' | translate }}
            </span>

            <by-currency-format [currencyValue]="payment.value.maxAmount">
            </by-currency-format>
          </div>
        </td>
      </tr>
    }
    <tr class="no-hover">
      <td colspan="100%" class="border-none">
        <div nz-row>
          <div
            nz-col
            [nzSpan]="7"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--total by-pb-10"
          >
            <div class="title">{{ 'total' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format [currencyValue]="maxAmountToCancel">
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave by-pr-10 color-total"></i>
            </div>
          </div>
          <div
            nz-col
            [nzSpan]="7"
            [nzOffset]="1"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--paid by-pb-10"
          >
            <div class="title">{{ 'refunded' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format [currencyValue]="balanceToCancel">
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave by-pr-10 color-paid"></i>
            </div>
          </div>
          <div
            nz-col
            [nzSpan]="7"
            [nzOffset]="1"
            class="by-flex by-flex-direction--column by-gap-5 box-container box-container--balance by-pb-10"
          >
            <div class="title">{{ 'balance' | translate | upperfirst }}</div>
            <div class="by-flex by-justify-content--space-between">
              <span class="bolder by-fs-16">
                <by-currency-format
                  [currencyValue]="maxAmountToCancel - balanceToCancel"
                >
                </by-currency-format>
              </span>
              <i class="fal fa-money-bill-wave color-balance"></i>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>
