import {
  Component,
  forwardRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionModule } from '@app/components/question/question.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { DecimalOnlyModule } from '@app/ui/directives/decimal-only/decimal-only.module';
import { CreditNoteVirtualStamp } from '@app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'by-credit-note-virtual-stamp-register',
  standalone: true,
  imports: [
    CommonModule,
    QuestionModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NzSelectModule,
    NzInputNumberModule,
    NzGridModule,
    ReactiveFormsModule,
    FormsModule,
    DecimalOnlyModule,
  ],
  templateUrl: './credit-note-virtual-stamp-register.component.html',
  styleUrl: './credit-note-virtual-stamp-register.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditNoteVirtualStampRegisterComponent),
      multi: true,
    },
  ],
})
export class CreditNoteVirtualStampRegisterComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  onChange: (value: Partial<CreditNoteVirtualStamp>) => void = () => {};

  onTouched: () => void = () => {};

  private fb = inject(FormBuilder);

  private subs = new Subscription();

  form = this.fb.group({
    createStamp: this.fb.control<number>(0),
    chargeToCustomer: this.fb.control<number>(0),
    stampAmount: this.fb.control<number>(0),
  });

  ngOnInit() {
    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
      }),
    );
  }

  writeValue(value: CreditNoteVirtualStamp): void {
    if (!value) {
      this.form.setValue({
        createStamp: 0,
        chargeToCustomer: 0,
        stampAmount: 0,
      });
      return;
    }

    this.form.patchValue({ ...value });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get createStampEnabled() {
    return this.form?.value?.createStamp === 1;
  }

  formatterCurrency = (value: number): string => `€ ${value}`;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
