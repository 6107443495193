import { Pipe, PipeTransform } from '@angular/core';
import { sumBy } from 'lodash';
import { CreditNoteRow } from '../../../../models';

@Pipe({
  name: 'generateGroupTotals',
  standalone: true,
})
export class GenerateGroupTotalsPipe implements PipeTransform {
  transform(rows?: Partial<CreditNoteRow>[]): number {
    if (!rows?.length) {
      return 0;
    }

    return sumBy(
      rows.filter(({ selected }) => selected),
      'rowAmount.amount',
    );
  }
}
