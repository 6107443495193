<div nz-form [formGroup]="form">
  <by-question
    formControlName="createStamp"
    [label]="'question_want_apply_virtual_stamp' | translate | upperfirst"
    [yesValue]="1"
    [noValue]="0"
  >
  </by-question>

  @if (createStampEnabled) {
    <nz-form-item class="box-padding">
      <nz-form-label [nzSpan]="20" class="bt-pt-5">
        {{ 'enter_amount' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control [nzSpan]="4">
        <nz-input-number
          class="by-w-100"
          byDecimalOnly
          [nzMin]="0"
          [nzStep]="1"
          formControlName="stampAmount"
          [nzFormatter]="formatterCurrency"
        ></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="by-pt-15 box-padding">
      <nz-form-label [nzSpan]="20" class="bt-pt-5">
        {{ 'virtual_stamp_on_customer' | translate | upperfirst }}
      </nz-form-label>
      <nz-form-control [nzSpan]="4">
        <nz-select class="by-w-100" formControlName="chargeToCustomer">
          <nz-option
            [nzValue]="1"
            [nzLabel]="'yes' | translate | upperfirst"
          ></nz-option>
          <nz-option
            [nzValue]="0"
            [nzLabel]="'no' | translate | upperfirst"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  }
</div>
