import {
  Component,
  effect,
  inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderModule } from '@app/components/page-header';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ReactiveFormsModule } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalModule, NzModalRef } from 'ng-zorro-antd/modal';
import {
  ICreditNoteRequestRoot,
  InvoiceDetails,
  PaymentsCreditNote,
} from '@app/models';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { CurrencyFormatComponent } from '@app/ui';
import { CreditNoteRegisterFormComponent } from '../credit-note-register-form/credit-note-register-form.component';
import { select, Store } from '@ngrx/store';
import {
  PaymentMethodsStoreActions,
  PaymentMethodsStoreSelectors,
} from '@app/root-store/payment-methods-store';
import { MediasStoreSelectors } from '@app/root-store/medias-store';

import { GetPipeModule } from '@app/pipes/get/get.module';
import { ApiOvverrideBill } from '../state/api-ovverride-bills.store';
import { calculateCityTaxMaxRefundable } from '@app/helpers/calc-city-tax-max-refundable';
import { NotificationService } from '@app/ui/services/notification.service';

@Component({
  selector: 'by-credit-note-register-page',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzModalModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    CurrencyFormatComponent,
    CreditNoteRegisterFormComponent,
    GetPipeModule,
  ],
  templateUrl: './credit-note-register-page.component.html',
  styleUrl: './credit-note-register-page.component.scss',
  providers: [ApiOvverrideBill],
})
export class CreditNoteRegisterPageComponent implements OnInit {
  @ViewChild(CreditNoteRegisterFormComponent)
  creditNoteRegisterForm: CreditNoteRegisterFormComponent;

  private nzModalData: {
    invoice: InvoiceDetails;
    payload: Partial<ICreditNoteRequestRoot>;
  } = inject(NZ_MODAL_DATA);

  private modalRef = inject(NzModalRef);

  private store = inject(Store);

  private notification = inject(NotificationService);

  invoice: InvoiceDetails = this.nzModalData.invoice;

  payload: Partial<ICreditNoteRequestRoot> = this.nzModalData.payload;

  currentStep = signal<'total' | 'bills'>('total');

  paymentMethodsGeneric$ = this.store.pipe(
    select(PaymentMethodsStoreSelectors.selectAllPaymentMethodsItems),
  );

  mediasInvoiceLayouts$ = this.store.pipe(
    select(MediasStoreSelectors.selectFlattenedLayoutsByType('invoicelayout')),
  );

  ovverrideBillStore = inject(ApiOvverrideBill);

  constructor() {
    effect(() => {
      if (this.currentStep() !== 'total') {
        this.modalRef.updateConfig({ nzWidth: 900 });
        return;
      }

      this.modalRef.updateConfig({ nzWidth: 575 });
    });
  }

  ngOnInit(): void {
    this.store.dispatch(
      new PaymentMethodsStoreActions.LoadRequestAction({
        propertyId: this.invoice.property_id,
      }),
    );
  }

  generateRefundablePayments(params: {
    refundSameTime: boolean;
    isUserPaymentMethodSelected: boolean;
    payments: PaymentsCreditNote[];
    totalValue: number;
    isTotalRefund: boolean;
    virtualStampCustomerCharged: number;
  }) {
    const {
      refundSameTime,
      isUserPaymentMethodSelected,
      payments,
      totalValue,
      isTotalRefund,
      virtualStampCustomerCharged,
    } = params;

    if (!refundSameTime || !isUserPaymentMethodSelected) {
      return [];
    }

    if (isTotalRefund && !virtualStampCustomerCharged) {
      return payments.map((payment) => ({
        id: payment.id,
        amount: payment.maxAmount,
      }));
    }

    if (payments.length === 1) {
      return payments.map((payment) => ({
        id: payment.id,
        amount: totalValue - virtualStampCustomerCharged,
      }));
    }

    return payments
      .filter(({ currentAmount }) => currentAmount)
      .map(({ currentAmount, id }) => ({ id, amount: currentAmount }));
  }

  submit() {
    const {
      refundSameTime,
      totalValue,
      chargeAmountAligned,
      payments,
      paymentMethodSelected,
      virtualStamp,
    } = this.creditNoteRegisterForm.formValues;

    const {
      isUserPaymentMethodSelected,
      isInvoiceTotalSuspended,
      isRefunbledPaymetsNotFilled,
      isCreditNotePartial,
      virtualStampCustomerCharged,
    } = this.creditNoteRegisterForm;

    if (
      (payments.length > 1 &&
        isUserPaymentMethodSelected &&
        isRefunbledPaymetsNotFilled) ||
      (virtualStampCustomerCharged && isRefunbledPaymetsNotFilled)
    ) {
      this.notification.warning('credit_note_cash_out_register_warning');
      return;
    }

    const refundable_payments = this.generateRefundablePayments({
      refundSameTime: Boolean(refundSameTime),
      isUserPaymentMethodSelected,
      payments,
      totalValue,
      isTotalRefund: !isCreditNotePartial,
      virtualStampCustomerCharged,
    });

    const payment_method_id = isUserPaymentMethodSelected
      ? null
      : paymentMethodSelected;

    const mark_as_paid = isInvoiceTotalSuspended ? 1 : refundSameTime;

    const partial_refund = chargeAmountAligned ? 'force_change' : 'default';

    const virtual_stamp_amount = virtualStamp?.createStamp
      ? virtualStamp.stampAmount
      : null;

    const virtual_stamp_on_customer = virtualStamp?.createStamp
      ? virtualStamp.chargeToCustomer
      : null;

    if (!this.creditNoteRegisterForm.isCreditNotePartial) {
      this.modalRef.close({
        ...this.payload,
        total: 1,
        mark_as_paid,
        payment_method_id,
        refundable_payments,
        virtual_stamp_amount,
        virtual_stamp_on_customer,
      });
      return;
    }

    if (this.creditNoteRegisterForm.isReversalTypeImport) {
      this.modalRef.close({
        ...this.payload,
        total: 0,
        mark_as_paid,
        partial_refund,
        partial_computation_mode: 'fixed',
        partial_computation_value: totalValue,
        payment_method_id,
        refundable_payments,
        virtual_stamp_amount,
        virtual_stamp_on_customer,
      });
      return;
    }

    this.modalRef.close({
      ...this.payload,
      exclude_taxes: false,
      payment_method_id,
      refundable_payments,
      total: 0,
      mark_as_paid,
      partial_refund,
      partial_computation_mode: 'custom',
      partial_computation_value: this.creditNoteRegisterForm.tableRows.total,
      bill_rows: this.creditNoteRegisterForm.tableRows.rows,
      virtual_stamp_amount,
      virtual_stamp_on_customer,
    });
  }

  closeModal() {
    this.modalRef.close();
  }

  get isTypeCreditNoteSelected() {
    return this.creditNoteRegisterForm?.creditNoteTypeValue;
  }

  goTo(step: 'total' | 'bills') {
    this.creditNoteRegisterForm.resetPayments();

    this.currentStep.set(step);
  }

  get isReversalTypeBills() {
    return this.creditNoteRegisterForm.form.value.reversalType === 'bills';
  }

  get showGenerateCreditNoteButton() {
    return (
      this.creditNoteRegisterForm.form.value.creditNoteType === 'total' ||
      !this.isReversalTypeBills
    );
  }

  get showNextButton() {
    const { isReversalTypeBills, isCreditNotePartial } =
      this.creditNoteRegisterForm;

    return (
      this.currentStep() === 'total' &&
      isReversalTypeBills &&
      isCreditNotePartial
    );
  }

  get isOnlyCityTaxRefund() {
    return (
      this.invoice.maxRefundable === 0 &&
      calculateCityTaxMaxRefundable(this.invoice.bills_list) > 0
    );
  }
}
