<div
  nz-form
  [formGroup]="form"
  class="by-flex by-flex-direction--column by-gap-20"
>
  @if (isTotalStep) {
    <div nz-row>
      <div nz-col [nzSpan]="14">
        {{ 'credit_note_typology' | translate | upperfirst }}
      </div>

      <div nz-col [nzSpan]="10">
        <nz-radio-group
          formControlName="creditNoteType"
          nzButtonStyle="solid"
          class="full-width-radio-group"
        >
          <label
            nz-radio-button
            nzValue="partial"
            class="full-width-button custom-radio-remove-focus-within"
          >
            {{ 'partial' | translate | upperfirst }}
          </label>
          <label
            nz-tooltip
            [nzTooltipTitle]="
              'info_not_possible_total_credit_note' | translate | upperfirst
            "
            [nzTooltipTrigger]="hasInvoiceLinkedDocuments ? 'hover' : null"
            nz-radio-button
            nzValue="total"
            class="full-width-button custom-radio-remove-focus-within"
            [nzDisabled]="hasInvoiceLinkedDocuments"
          >
            {{ 'total' | translate | upperfirst }}
          </label>
        </nz-radio-group>
      </div>
    </div>

    @if (isCreditNotePartial) {
      <div nz-row>
        <div nz-col [nzSpan]="14">
          {{ 'question_what_you_want_cancel' | translate | upperfirst }}
          <i
            class="far fa-info-circle pointer color--orange"
            nz-tooltip
            [nzTooltipTitle]="
              'info_question_credit_note_what_cancel' | translate | upperfirst
            "
          ></i>
        </div>

        <div nz-col [nzSpan]="10">
          <nz-radio-group
            formControlName="reversalType"
            nzButtonStyle="solid"
            class="full-width-radio-group"
          >
            <label
              nz-radio-button
              nzValue="import"
              [nzDisabled]="isOnlyCityTaxRefund"
              class="full-width-button"
            >
              {{ 'import' | translate | upperfirst }}
            </label>
            <label nz-radio-button nzValue="bills" class="full-width-button">
              {{ 'single_bills' | translate | upperfirst }}
            </label>
          </nz-radio-group>
        </div>
      </div>
    }
    @if (isOnlyCityTaxRefund) {
      <nz-alert
        nzType="warning"
        [nzMessage]="'warning_credit_note_only_tax' | translate | upperfirst"
        nzShowIcon
      ></nz-alert>
    }
  }

  @if (isCreditNotePartial && !isReversalTypeBills) {
    <div class="by-w-100">
      <by-credit-note-register-slider
        [maxValue]="invoice.maxRefundable"
        formControlName="totalValue"
        [invoice]="invoice"
      ></by-credit-note-register-slider>
    </div>
  }

  @if (!isTotalStep) {
    <by-credit-note-register-bills-table
      [invoice]="invoice"
      [mediasInvoiceLayouts]="mediasInvoiceLayouts"
    ></by-credit-note-register-bills-table>
  }

  @if (
    !(this.isTotalStep && this.isCreditNotePartial && this.isReversalTypeBills)
  ) {
    <div nz-row [nzGutter]="[0, 20]">
      @if (isCreditNotePartial && isInvoiceFromReservation) {
        <div nz-col [nzSpan]="24">
          <by-question
            [labelTemplate]="titleChargeAmountAligned"
            formControlName="chargeAmountAligned"
            [yesValue]="1"
            [noValue]="0"
          >
          </by-question>

          <ng-template #titleChargeAmountAligned>
            <span
              >{{ 'question_charge_amount_aligned' | translate | upperfirst }}
            </span>

            <i
              class="far fa-info-circle pointer color--orange"
              nz-tooltip
              [nzTooltipTitle]="toolTipTitleAmountAligned"
            ></i>

            <ng-template #toolTipTitleAmountAligned>
              <div
                [innerHtml]="
                  'info_charge_amount_aligned' | translate | upperfirst
                "
              ></div>
            </ng-template>
          </ng-template>
        </div>
      }

      @if (isCreditNotePartial && !isInvoiceFromReservation) {
        <nz-alert
          class="by-pb-10"
          nzType="warning"
          [nzMessage]="
            'info_partial_credit_note_charge_case' | translate | upperfirst
          "
        >
        </nz-alert>
      }

      @if (creditNoteTypeValue && !isInvoiceTotalSuspended) {
        <div nz-col [nzSpan]="24">
          <by-credit-note-virtual-stamp-register
            formControlName="virtualStamp"
          ></by-credit-note-virtual-stamp-register>
        </div>

        <div nz-col [nzSpan]="24">
          <by-question
            [labelTemplate]="titleRefundSameTime"
            formControlName="refundSameTime"
            [yesValue]="1"
            [noValue]="0"
          >
          </by-question>

          <ng-template #titleRefundSameTime>
            <span
              >{{
                'question_you_want_to_issue_the_refund_same_time'
                  | translate
                  | upperfirst
              }}
            </span>
            <i
              class="far fa-info-circle pointer color--orange"
              nz-tooltip
              [nzTooltipTitle]="
                'info_question_want_issue_refund_same_time'
                  | translate
                  | upperfirst
              "
            ></i>
          </ng-template>
        </div>
      }

      @if (showRefundSameTimeQuestion) {
        <div nz-col [nzSpan]="24">
          <nz-alert
            nzType="warning"
            [nzMessage]="titleWarning"
            nzShowIcon
            class="by-pb-5"
          ></nz-alert>

          <ng-template #titleWarning>
            <div
              [innerHtml]="'warning_pos_credit_note' | translate | upperfirst"
            ></div>
          </ng-template>

          <nz-form-item class="by-mt-10">
            <nz-form-label class="bolder" [nzSpan]="24" nzRequired>
              {{ 'payment_method' | translate | upperfirst }}
            </nz-form-label>
            <nz-form-control [nzSpan]="24">
              <nz-select
                class="by-w-100"
                formControlName="paymentMethodSelected"
              >
                <nz-option
                  [nzValue]="0"
                  [nzLabel]="
                    'refound_credit_note_option'
                      | translate: { documentType: 'invoice' | translate }
                      | upperfirst
                  "
                ></nz-option>
                @for (
                  paymentMethod of paymentMethodsGeneric || [];
                  track paymentMethod.id
                ) {
                  @if (
                    invoicingAllowedPaymentMethodsIds
                      | firstOrDefault: paymentMethod.id
                  ) {
                    <nz-option
                      [nzLabel]="paymentMethod.name"
                      [nzValue]="paymentMethod.id"
                    ></nz-option>
                  }
                }</nz-select
            ></nz-form-control>
          </nz-form-item>

          @if (showSplitPayments) {
            <by-page-header
              [titleTemplate]="title"
              [byStyle]="{
                'padding-top': 0,
                'margin-bottom': '15px'
              }"
            ></by-page-header>
            <ng-template #title>
              <span>
                {{
                  'title_split_import_on_payments'
                    | translate
                      : {
                          amount: isReversalTypeBills
                            ? '€ ' +
                              (totaToCancelSplitTable -
                                virtualStampCustomerCharged
                                | currency: '' : '' : '1.2-2')
                            : '€ ' +
                              (totalToCancel - virtualStampCustomerCharged
                                | currency: '' : '' : '1.2-2')
                        }
                    | upperfirst
                }}
              </span>
            </ng-template>

            <by-credit-note-payments-table
              [maxAmountToCancel]="amountPayments"
              formControlName="payments"
            ></by-credit-note-payments-table>
          }
        </div>
      }
    </div>
  }
</div>
