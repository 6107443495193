import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceDetails } from '@app/models';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';

@Pipe({
  name: 'invoiceContractorLabel',
})
export class InvoiceContractorLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(
    invoice: InvoiceDetails,
    contractorType: 'sender' | 'destination',
  ): string {
    const invoiceContractorType =
      contractorType === 'destination' ? 'recipient' : 'sender';

    const contractorId =
      contractorType === 'destination'
        ? invoice?.destination?.id
        : invoice?.sender?.id;

    return upperFirst(
      contractorId
        ? this.translate.instant('change_item', {
            item: this.translate.instant(invoiceContractorType),
          })
        : this.translate.instant('add_item', {
            item: this.translate.instant(invoiceContractorType),
          }),
    );
  }
}
