<nz-input-number-group [nzAddOnBefore]="addOnBeforeTemplate">
  <nz-input-number
    [nzMin]="0"
    [nzMax]="form?.value?.type === 'import' ? maxValue : 100"
    [nzStep]="1"
    [ngModel]="
      form.value?.type === 'import' ? form.value?.amount : calculatePercentage
    "
    (ngModelChange)="handleValueChange($event)"
    [nzDisabled]="disabled"
  ></nz-input-number>
</nz-input-number-group>
<ng-template #addOnBeforeTemplate>
  <nz-select
    [ngModel]="form.value.type"
    (ngModelChange)="handleTypeChange($event)"
    [nzDisabled]="disabled"
  >
    <nz-option nzLabel="€" nzValue="import"></nz-option>
    <nz-option nzLabel="%" nzValue="percentage"></nz-option>
  </nz-select>
</ng-template>
