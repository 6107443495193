import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  generateMultiLevelArraySearchQuery,
  generateSearchQuery,
} from '@app/core/helpers/params-generator';
import { IResponse } from '@app/core/models/response.model';
import {
  ICreditNoteRequestRoot,
  IInvoiceOverride,
  IInvoicesRequestRoot,
  InvoiceDetails,
  InvoiceListExportFileRequest,
  InvoiceOvverideRowSuccess,
  InvoicePrintingSessionRequest,
  InvoiceTypes,
  InvoiceWidgetsPeriod,
  InvoiceWidgetTypes,
  IReverseAutoinvoiceRequestRoot,
  RegisterInvoice,
  ResendSdi,
  ReservationsStatsWidgetRequest,
  SendMailInvoiceRequest,
} from '@app/models';
import { omit } from 'lodash';
import { forkJoin, throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IResponseSuccess } from '../core/models/response-sucess.model';
import { DateFormatterService } from '../core/services/date-formatter.service';
import { removeAllNullishValues } from '../helpers';
import { InvoiceReceiptXml } from '../models/objects/invoice-receipt-xml';
import { SearchParams } from '../models/objects/search-params';
import { RegisterInvoiceService } from '../shared/invoice/models/register-invoice-service.model';
import { ExportFileRequestSuccess } from '../models/types/export-file-request';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService implements RegisterInvoiceService {
  constructor(
    private http: HttpClient,
    private _dateFormatter: DateFormatterService,
  ) {}

  loadInvoicesBySdiStatus(params: SearchParams) {
    const getParam = generateSearchQuery(params);
    return this.http.get(`invoices/sdi?${getParam}`);
  }

  load(params: SearchParams) {
    return this.http.get(`invoices?${generateSearchQuery(params)}`);
  }

  connectFollowReceipt(params: { invoiceId: number; receipts: number[] }) {
    const { invoiceId, receipts } = params;

    return this.http.post(`invoices/${invoiceId}/sync_receipts`, { receipts });
  }

  create(data: IInvoicesRequestRoot) {
    return this.http.post(``, data);
  }

  createCreditNote(data: ICreditNoteRequestRoot) {
    return this.http.post(`credit_note`, data) as Observable<
      IResponseSuccess<InvoiceDetails[]>
    >;
  }

  ovverideRowValues(overrideId: number, amount: number) {
    return this.http.post<IResponseSuccess<InvoiceOvverideRowSuccess[]>>(
      `invoices/override/${overrideId}/partials`,
      {
        amount,
      },
    );
  }

  createReverseAutoinvoice(
    data: IReverseAutoinvoiceRequestRoot,
  ): Observable<IResponseSuccess<InvoiceDetails[]>> {
    return this.http.post(`reverse_auto_invoice`, data) as Observable<
      IResponseSuccess<InvoiceDetails[]>
    >;
  }

  resendSdi(data: ResendSdi) {
    return this.http.post(`invoice/${data.invoice_id}/sdi_resend`, data);
  }

  delete(invoiceId: number, forceOperation?: boolean) {
    return this.http.delete(
      `invoice/${invoiceId}?${generateSearchQuery(
        removeAllNullishValues({
          force_operation: forceOperation,
        }),
      )}`,
    );
  }

  update(
    invoiceId: number,
    data: Partial<InvoiceDetails>,
    force_operation: boolean = false,
  ): Observable<IResponseSuccess> {
    return this.http.put<IResponseSuccess>(`invoices/${invoiceId}`, {
      ...data,
      force_operation,
      now: this._dateFormatter.toServerFormat(new Date()),
    });
  }

  sendEmail(data: SendMailInvoiceRequest) {
    return this.http.post(`invoices/sendmailinvoice`, data);
  }

  loadDetailShow(invoice_id: number): Observable<any> {
    return this.http
      .get(`invoices/${invoice_id}/show`, {
        responseType: 'text',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          error = { ...error, error: JSON.parse(error.error) };
          return throwError(error);
        }),
      );
  }

  loadDetail(invoice_id: number): Observable<any> {
    return this.http.get(`invoices/${invoice_id}`);
  }

  loadTemplateEmail(invoice_id: number): Observable<any> {
    return this.http.get(`invoices/getinfosendmail?invoice_id=${invoice_id}`);
  }

  loadStatusSdiInfo(): Observable<any> {
    return this.http.get(`general/invoicestatusinfo`);
  }

  overrideInvoice(
    data: IInvoiceOverride,
    edit,
    reservationsBillsOverrideIdMerge?: number[],
  ) {
    if (!edit) {
      return this.http.post(`invoices/overrides`, data);
    }
    return forkJoin(
      ...(reservationsBillsOverrideIdMerge || []).map((id) =>
        this.deleteOverride(id),
      ),
      this.http.put(`invoices/override/${data.bill_override_id}`, data),
    );
  }

  deleteOverride(bill_override_id) {
    return this.http.delete(`invoices/override/${bill_override_id}`);
  }

  restoreBill(bill_id: number) {
    return this.http.put(`invoices/${bill_id}/restore`, {});
  }

  mergeInvoices(payload) {
    return this.http.post(`invoices/merge`, payload);
  }

  loadWidgets(
    widget_type: InvoiceWidgetTypes,
    filters: SearchParams,
    periods: InvoiceWidgetsPeriod[],
  ) {
    return this.http.get(
      `invoices/widget?${generateSearchQuery({
        ...filters,
        widget_type,
      })}&${generateMultiLevelArraySearchQuery('periods', periods)}`,
    );
  }

  restoreInvoice(invoiceId: number) {
    return this.http.post(`invoice/${invoiceId}/restore`, {});
  }

  public loadInvoiceNextNumber(
    invoice_layout_id: number,
    type: InvoiceTypes,
    invoice_layout_sectional_id?: number,
    year?: number,
  ): Observable<IResponse> {
    return this.http.get<IResponse>(
      `invoices/layout/${invoice_layout_id}/get_next_number?${generateSearchQuery(
        removeAllNullishValues({
          type,
          year,
          ...(invoice_layout_sectional_id && { invoice_layout_sectional_id }),
        }),
      )}`,
    );
  }

  updateInvoice(data: Partial<InvoiceDetails>) {
    return this.http.put(`invoices/${data.invoice_id}`, data);
  }

  register(data: RegisterInvoice) {
    return this.http.post(`invoice/${data.invoice_id}/register`, {
      ...data,
      now: data.now ? data.now : this._dateFormatter.toServerFormat(new Date()),
    }) as Observable<IResponseSuccess<InvoiceDetails[]>>;
  }

  getPrinterXmlForRegister(
    invoiceId: number,
    payload: RegisterInvoice,
  ): Observable<InvoiceReceiptXml> {
    return this.http
      .post<{ data: InvoiceReceiptXml[] }>(`invoice/${invoiceId}/xml`, payload)
      .pipe(map(({ data }) => data[0]));
  }

  updatePrintingSession(
    invoiceId: number,
    printSessionId: number,
    payload: InvoicePrintingSessionRequest,
  ) {
    return this.http.put<IResponseSuccess>(
      `invoice/${invoiceId}/print_session/${printSessionId}`,
      omit(payload, 'notification'),
    );
  }

  loadWidget(data: ReservationsStatsWidgetRequest) {
    return this.http.get(
      `statistics/invoices_totals?${generateSearchQuery(data)}`,
    );
  }

  exportFile(data: InvoiceListExportFileRequest) {
    return this.http.get<IResponseSuccess<ExportFileRequestSuccess>>(
      `invoices?${generateSearchQuery(removeAllNullishValues(data))}`,
    );
  }
}
