<div nz-row nzType="flex" nzAlign="top" nzJustify="space-between">
  <div nz-col nzSm="12" nzXs="24" class="text-align--start by-p-5">
    <ng-container
      *ngTemplateOutlet="
        senderTplType === 'customer' ? customerTpl : layoutTpl;
        context: { contractorType: 'sender', contractor: invoice?.sender }
      "
    ></ng-container>
  </div>

  <div nz-col nzSm="12" nzXs="24" class="text-align--end by-p-5">
    <ng-container
      *ngTemplateOutlet="
        destinationTplType === 'customer' ? customerTpl : layoutTpl;
        context: {
          contractorType: 'destination',
          contractor: invoice?.destination
        }
      "
    ></ng-container>
  </div>
</div>
<div nz-row>
  <div nz-col>
    <div class="by-p-5 by-mt-2">
      <ng-container *ngIf="!invoice?.reservation_id && !showNumberComplete">
        <div class="color--azure">
          <strong>
            {{
              (showNumberComplete ? invoice?.number_complete : invoice?.label)
                | upperfirst
            }}
          </strong>
        </div>

        <div *ngIf="invoice?.clousure_number" class="by-fs-12">
          {{ 'clousure_number' | translate | upperfirst }}:
          {{ invoice?.clousure_number }}
        </div>

        <div *ngIf="invoice?.registered" class="by-fs-12">
          {{ invoiceDate | date: 'dd' }}
          {{ invoiceDate | date: 'MMMM' | lowercase | translate | upperfirst }}
          {{ invoiceDate | date: 'y' }}
        </div>

        <div
          *ngIf="!invoice?.registered"
          nz-tooltip
          [nzTooltipTitle]="'edit' | translate | upperfirst"
          (click)="editLabelOrDate.emit()"
          class="label-button"
        >
          {{ 'edit_charge_name' | translate | upperfirst }}
        </div>
      </ng-container>
      <ng-container *ngIf="invoice?.reservation_id || showNumberComplete">
        <div class="color--azure by-flex">
          <strong>
            {{
              (!showNumberComplete && invoice?.type !== 'credit_note'
                ? 'bill'
                : invoice?.reversal
                  ? 'reversal_receipt'
                  : invoiceTypes?.[invoice?.document_type_id]?.name ||
                    invoice?.type
              )
                | translate
                | upperfirst
            }}
            {{ isFollowInvoice ? ('follow_invoice' | translate) : '' }}
            {{ showNumberComplete ? 'n.' : '#'
            }}{{ showNumberComplete ? invoice?.number_complete : invoice?.id }}
          </strong>
          <i
            *ngIf="invoice?.manual_fp_register"
            class="by-pt-1 by-pl-5 pointer far fa-exclamation-square color--azure"
            nz-tooltip
            [nzTooltipTitle]="
              'receipt_manual_registered' | translate | upperfirst
            "
          ></i>
        </div>

        <div *ngIf="invoice?.clousure_number" class="by-fs-12">
          {{ 'clousure_number' | translate | upperfirst }}:
          {{ invoice?.clousure_number }}
        </div>

        <div class="by-fs-12">
          {{ invoiceDate | date: 'dd' }}
          {{ invoiceDate | date: 'MMMM' | lowercase | translate | upperfirst }}
          {{ invoiceDate | date: 'y' }}
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template
  #layoutTpl
  let-contractorType="contractorType"
  let-contractor="contractor"
>
  <div
    nz-row
    nzType="flex"
    nzAlign="middle"
    [nzJustify]="contractorType === 'sender' ? 'start' : 'end'"
  >
    <div nz-col class="by-mr-2">
      <ng-container *ngIf="contractorType === 'sender'">
        <ng-container
          *ngTemplateOutlet="
            invoiceLayoutLogoTpl;
            context: { contractorType: contractorType }
          "
        ></ng-container>
      </ng-container>
    </div>
    <div nz-col>
      <ng-container
        *ngTemplateOutlet="
          senderTpl;
          context: {
            contractorType: contractorType,
            contractor: contractor
          }
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #senderTpl
  let-contractorType="contractorType"
  let-contractor="contractor"
>
  <div class="invoice-layout__infos">
    <span class="invoice-layout__name">
      {{ contractor && contractor?.business_name }}</span
    >
    <span>
      <ng-container *ngIf="contractor && contractor?.address">
        <div>{{ contractor?.address }}</div>
        <div>
          <ng-container *ngIf="contractor?.zip_code">
            {{ contractor?.zip_code }}
          </ng-container>
          <ng-container *ngIf="contractor?.city_name">
            {{ contractor?.city_name }}
          </ng-container>
          <ng-container *ngIf="contractor?.county_name">
            ({{ contractor?.county_iso || contractor?.county_name }}),
          </ng-container>
          <ng-container *ngIf="contractor?.country_name">
            {{ contractor?.country_name }}
          </ng-container>
        </div>
      </ng-container></span
    >
    <span *ngIf="contractor && contractor?.vat_code"
      >{{ 'vat_title' | translate }}: {{ contractor?.vat_code }}</span
    >
    <div>
      <span *ngIf="contractor && contractor?.nic"
        >{{ 'short__tax_code' | translate | uppercase }}:
        {{ contractor?.nic }}</span
      >
    </div>
    <div *ngIf="contractorType === 'destination'">
      <span>{{ 'sdi' | translate | uppercase }}: </span>
      <ng-container *ngIf="contractor?.receiver_code; else noReciverCodeTpl">
        <span>{{ contractor?.receiver_code }}</span>
      </ng-container>
      <ng-template #noReciverCodeTpl>
        <ng-container
          *ngIf="contractor?.country_id === 1; else foreignPlaceHolderTpl"
        >
          <span>{{ defaultItalianReceiverCode }}</span>
        </ng-container>
        <ng-template #foreignPlaceHolderTpl>
          <span>{{ defaultNotItalianReceiverCode }}</span>
        </ng-template>
      </ng-template>
    </div>
    <div
      *ngIf="!invoice?.registered"
      class="label-button by-mt-5"
      (click)="changeInvoiceLayout.emit()"
    >
      {{ 'change_invoice_layout' | translate | upperfirst }}
    </div>
  </div>
</ng-template>

<ng-template #invoiceLayoutLogoTpl let-contractorType="contractorType">
  <span class="invoice-layout__logo">
    <img
      width="100"
      *ngIf="invoiceLayoutLogo; else noLogo"
      [src]="invoiceLayoutLogo"
    />
    <ng-template #noLogo>
      <i class="fal fa-image"></i>
    </ng-template>
  </span>
</ng-template>

<ng-template
  #customerTpl
  let-contractorType="contractorType"
  let-contractor="contractor"
>
  <div
    class="by-flex by-flex-middle"
    [class.by-flex-end]="contractorType === 'destination'"
  >
    <div
      class="by-mr-2"
      *ngIf="
        contractorType === 'sender' &&
        invoice?.document_type_code_rules?.sender_is_layout
      "
    >
      <ng-container
        *ngTemplateOutlet="
          invoiceLayoutLogoTpl;
          context: { contractorType: contractorType }
        "
      ></ng-container>
    </div>
    <div>
      <ng-container *ngIf="contractor?.id">
        <span class="color--azure">
          {{
            (contractorType === 'destination' ? 'recipient' : contractorType)
              | translate
              | upperfirst
          }}</span
        >
        <div>
          <strong
            [class.pointer]="
              isEditable && contractorType === 'sender'
                ? showEditSenderCustomer
                : isEditable
            "
            class="destination-name"
            (click)="
              isEditable &&
              contractorType === 'sender' &&
              showEditSenderCustomer
                ? editDestination.emit(contractorType)
                : isEditable && contractorType === 'destination'
                  ? editDestination.emit(contractorType)
                  : null
            "
          >
            <i
              *ngIf="
                isEditable && contractorType === 'sender'
                  ? showEditSenderCustomer
                  : isEditable
              "
              class="fas fa-pencil-alt"
            ></i>
            <!-- {{ invoice | invoiceDestination }} -->
            <span *ngIf="contractor?.business_name">{{
              contractor?.business_name
            }}</span>
            <span *ngIf="!contractor?.business_name"
              >{{ contractor?.surname }} {{ contractor?.name }}</span
            >
          </strong>
        </div>

        <div>
          <ng-container *ngIf="contractor?.address">
            {{ contractor?.address }}
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="contractor?.zip_code">
            {{ contractor?.zip_code }}
          </ng-container>
          <ng-container *ngIf="contractor?.city_name">
            {{ contractor?.city_name }}
          </ng-container>
          <ng-container *ngIf="contractor?.county_name">
            ({{ contractor?.county_iso || contractor?.county_name }}),
          </ng-container>
          <ng-container *ngIf="contractor?.country_name">
            {{ contractor?.country_name }}
          </ng-container>
        </div>

        <div *ngIf="contractor?.vat_code">
          {{ 'vat_title' | translate }}: {{ contractor?.vat_code }}
        </div>

        <div
          *ngIf="contractor?.nic && contractor?.vat_code !== contractor?.nic"
        >
          {{ 'short__tax_code' | translate | uppercase }}:
          {{ contractor?.nic }}
        </div>

        <div *ngIf="contractorType === 'destination'">
          <span>{{ 'sdi' | translate | uppercase }}: </span>
          <ng-container
            *ngIf="contractor?.receiver_code; else noReciverCodeTpl"
          >
            <span>{{ contractor?.receiver_code }}</span>
          </ng-container>
          <ng-template #noReciverCodeTpl>
            <ng-container
              *ngIf="contractor?.country_id === 1; else foreignPlaceHolderTpl"
            >
              <span>{{ defaultItalianReceiverCode }}</span>
            </ng-container>
            <ng-template #foreignPlaceHolderTpl>
              <span>{{ defaultNotItalianReceiverCode }}</span>
            </ng-template>
          </ng-template>
        </div>

        <div *ngIf="contractor?.lottery_code as lotteryCode">
          {{ 'lottery_code' | translate | upperfirst }}:
          {{ lotteryCode }}
        </div>

        <ng-container
          *ngIf="
            contractor?.country_id === 1 &&
            contractor?.pec &&
            contractor?.receiver_code &&
            contractor?.receiver_code === defaultItalianReceiverCode
          "
        >
          <span class="by-ml-5"
            >{{ 'pec_short' | translate | uppercase }}:
          </span>
          <span>{{ contractor?.pec }}</span>
        </ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="
          missingFieldsTpl;
          context: {
            fields:
              contractorType === 'sender'
                ? senderMissingFields
                : destinationMissingFields,
            show:
              contractorType === 'sender'
                ? showSenderMissingFields
                : showDestinationMissingFields
          }
        "
      ></ng-container>

      <ng-template #missingFieldsTpl let-fields="fields" let-show="show">
        <div class="color--red by-mt-5" *ngIf="show">
          <strong>
            *{{ 'complete_data_invoice' | translate | upperfirst }} :
          </strong>
          <ng-container *ngFor="let field of fields; let last = last">
            <span>{{ field | translate }}</span>
            <ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </div>
      </ng-template>
      <p
        *ngIf="invoice?.company_id || invoice?.customer_id as destinationId"
        class="text-info"
      >
        {{ 'id' | uppercase }}:{{ destinationId }}
      </p>
      <div>
        <span
          *ngIf="
            isEditable &&
            invoice?.type !== 'credit_note' &&
            contractorType === 'sender'
              ? showEditSenderCustomer
              : isEditable && invoice?.type !== 'credit_note'
          "
          class="label-button by-mt-5"
          (click)="changeDestination.emit(contractorType)"
        >
          {{ invoice | invoiceContractorLabel: contractorType }}
        </span>
      </div>
    </div>
  </div>
</ng-template>
