<by-invoice-header
  [invoice]="invoice"
  [invoiceLayoutLogo]="mediasInvoiceLayouts"
  [isEditable]="false"
></by-invoice-header>

<nz-table nzTemplateMode [nzShowPagination]="false">
  <thead>
    <tr>
      <th
        class="table__header by-pt-10 by-pb-5 checkbox-size"
        [nzIndeterminate]="
          !(groupRowValues | every: isAllRowsOnGroupAreSelected) &&
          (groupRowValues | some: isSomeRowOnGroupIsSelected)
        "
        [nzChecked]="groupRowValues | every: isAllRowsOnGroupAreSelected"
        (nzCheckedChange)="setAllChecked($event)"
      ></th>
      <th class="table__header by-pt-10 by-pb-5">
        {{ 'description' | translate | upperfirst }}
      </th>
      <th class="table__header by-pt-10 by-pb-5 by-w-10">
        {{ 'total' | translate | upperfirst }}
        <i
          class="far fa-info-circle pointer"
          nz-tooltip
          [nzTooltipTitle]="
            'total_shows_max_to_cancel' | translate | upperfirst
          "
        ></i>
      </th>
      <th class="table__header by-pt-10 by-pb-5 by-w-25 text-align--end">
        {{ 'reverse' | translate | upperfirst }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr class="credit-note-expand-table by-w-100">
      <td colspan="100%">
        @for (
          groupControl of groupsControls;
          track groupControl.value.groupId
        ) {
          <by-credit-note-bill-row
            [formControl]="groupControl"
            [cityTaxMaxRefundablesMap]="cityTaxMaxRefundablesMap"
          ></by-credit-note-bill-row>
        }
      </td>
    </tr>

    <tr>
      <td colspan="2" class="row-total text-align--end bolder">
        {{ 'total' | translate | uppercase }}:
      </td>
      <td class="row-total by-w-10 bolder">
        <by-currency-format
          [currencyValue]="invoice | maxRefundableWithCityTax"
        >
        </by-currency-format>
      </td>
      <td class="row-total by-w-25">
        @if (groupRowValues | generateTotalToCancel) {
          <div class="by-flex by-flex-between bolder">
            <span class="by-pl-10">
              {{ 'of_cancel' | translate | uppercase }}:
            </span>
            <by-spin
              nzSize="small"
              [nzSpinning]="ovverrideBillStore.loading$ | async"
            >
              <by-currency-format
                [currencyValue]="groupRowValues | generateTotalToCancel"
              >
              </by-currency-format>
            </by-spin>
          </div>
        }
      </td>
    </tr>
  </tbody>
</nz-table>
