import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from '@app/shared/ng-pipes/ng-pipes.module';

import { QuestionComponent } from './question.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';

@NgModule({
  declarations: [QuestionComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgPipesModule,
    TranslateModule,
    NzFormModule,
    NzRadioModule,
  ],
  exports: [QuestionComponent],
})
export class QuestionModule {}
