import { InvoiceRow } from '@app/models';
import { flatMap, sumBy } from 'lodash';

export function calculateCityTaxMaxRefundable(bills: InvoiceRow[]): number {
  if (!bills.length) {
    return 0;
  }

  const cityTaxRows = bills.filter(
    ({ production_type_label }) => production_type_label === 'tax',
  );

  if (cityTaxRows.length) {
    return sumBy(flatMap(cityTaxRows, 'max_refundables'), 'max_refundable');
  }

  return 0;
}
