import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';
import { ErrorHandlerService } from '../../../../core/services/error-handler.service';
import { InvoicesService } from '@app/services/invoices.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InvoiceOvverideRowSuccess } from '@app/models';

type OvverideBillRows = Map<number, number>;

interface OvverideBilState {
  loading: boolean;
  rows: OvverideBillRows;
}

interface OverrideBillRequest {
  groupId: number;
  amount: number;
}

@Injectable()
export class ApiOvverrideBill extends ComponentStore<OvverideBilState> {
  constructor(
    private readonly invoiceService: InvoicesService,
    private errorHandler: ErrorHandlerService,
  ) {
    super({ loading: false, rows: new Map() });
  }

  readonly overrideBillRows = this.effect(
    (trigger$: Observable<OverrideBillRequest>) =>
      trigger$.pipe(
        tap(() => this.setLoading(true)),
        switchMap(({ groupId, amount }) =>
          this.invoiceService.ovverideRowValues(groupId, amount).pipe(
            tapResponse(
              ({ data }) => {
                this.setStateWithAmounts(data);
                this.setLoading(false);
              },
              (error: HttpErrorResponse) => {
                this.errorHandler.handle(error);
                this.setLoading(false);
              },
            ),
          ),
        ),
      ),
  );

  readonly setStateWithAmounts = this.updater<InvoiceOvverideRowSuccess[]>(
    (state, data) => {
      return {
        ...state,
        rows: new Map(data.map(({ id, price }) => [id, price])),
      };
    },
  );

  readonly setLoading = this.updater((state, loading: boolean) => ({
    ...state,
    loading,
  }));

  readonly ovverideRow$ = this.select<OvverideBillRows>((state) => state.rows);

  readonly loading$ = this.select<boolean>((state) => state.loading);
}
