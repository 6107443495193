import { Pipe, PipeTransform } from '@angular/core';

import { keyBy } from 'lodash';

/**
 * This pipe take a array and return a Object as Map
 * with key the key passed as parameter
 */

@Pipe({
  name: 'keyBy',
  standalone: true,
})
export class KeyByPipe<T> implements PipeTransform {
  transform<K extends keyof T>(array: Array<T>, key: K): Record<string, T> {
    return keyBy(array, key);
  }
}
