import {
  Component,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  CreditNotePaymentsForm,
  PaymentsCreditNote,
  TypedSimpleChanges,
} from '@app/models';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { Subscription } from 'rxjs';
import { sumBy } from 'lodash';
import { CurrencyFormatComponent } from '@app/ui';
import { FormatDateModule } from '@app/pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
  selector: 'by-credit-note-payments-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NzTableModule,
    NgUpperFirstPipeModule,
    TranslateModule,
    NzInputNumberModule,
    CurrencyFormatComponent,
    FormatDateModule,
    NzGridModule,
  ],
  templateUrl: './credit-note-payments-table.component.html',
  styleUrl: './credit-note-payments-table.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditNotePaymentsTableComponent),
      multi: true,
    },
  ],
})
export class CreditNotePaymentsTableComponent
  implements ControlValueAccessor, OnDestroy, OnInit, OnChanges
{
  @Input({ required: true }) maxAmountToCancel: number;

  private fb = inject(FormBuilder);

  onChange: (value: Partial<PaymentsCreditNote>[]) => void = () => {};

  onTouched: () => void = () => {};

  payments = this.fb.array<CreditNotePaymentsForm>([]);

  maxAmountsMap: Record<number, number> = {};

  balanceToCancel = 0;

  private subs = new Subscription();

  ngOnInit(): void {
    this.subs.add(
      this.payments.valueChanges.subscribe((payments) => {
        this.balanceToCancel = sumBy(payments, 'currentAmount');

        this.updateMaxAmounts();

        this.onChange(payments);
      }),
    );
  }

  ngOnChanges(
    changes: TypedSimpleChanges<{ maxAmountToCancel: number }>,
  ): void {
    const { maxAmountToCancel } = changes;

    if (maxAmountToCancel) {
      if (this.maxAmountToCancel < this.balanceToCancel) {
        this.resetAllValues();
      } else {
        this.updateMaxAmounts();
      }
    }
  }

  writeValue(newPayments: PaymentsCreditNote[]): void {
    this.payments.clear({ emitEvent: false });

    if (newPayments && newPayments.length) {
      newPayments.forEach((payments) => {
        this.payments.push(this.fb.group({ ...payments }));
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateMaxAmounts(): void {
    const remainingAmount = this.maxAmountToCancel - this.balanceToCancel;

    this.payments.controls.forEach((group) => {
      const { id, maxAmount, currentAmount } = group?.value;

      this.maxAmountsMap[id] = Math.min(
        maxAmount,
        remainingAmount + currentAmount,
      );
    });
  }

  resetAllValues() {
    this.payments.controls.forEach((group, index, groups) => {
      group.controls.currentAmount.patchValue(0, {
        emitEvent: groups.length === index + 1,
      });
    });
  }

  setMaxAmount(paymentId: number, index: number): void {
    const maxAmount = this.maxAmountsMap[paymentId];
    this.payments.controls[index].controls.currentAmount.patchValue(maxAmount);
  }

  get isBalanceToCancelNotTotalSelected() {
    return this.balanceToCancel !== this.maxAmountToCancel;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
