import {
  Component,
  effect,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import {
  CreditNoteRowControls,
  CreditNoteRowForm,
  CreditNoteRowGroup,
  CreditNoteRowGroupControls,
  MaxRefundableBill,
} from '@app/models';
import { CurrencyFormatComponent } from '@app/ui';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgEveryPipeModule,
  NgSomePipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';

import { CreditNoteRegisterImportNumberComponent } from '../credit-note-register-import-number/credit-note-register-import-number.component';

import { GenerateGroupTotalsPipe } from '../pipes/generate-group-total.pipe';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import { ApiOvverrideBill } from '../state/api-ovverride-bills.store';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FormatDateModule } from '@app/pipes';

@Component({
  selector: 'by-credit-note-bill-row',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzToolTipModule,
    CurrencyFormatComponent,
    NzPopconfirmModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    CreditNoteRegisterImportNumberComponent,
    GenerateGroupTotalsPipe,
    ReactiveFormsModule,
    NzInputNumberModule,
    NgEveryPipeModule,
    NgSomePipeModule,
    FormatDateModule,
  ],
  templateUrl: './credit-note-bill-row.component.html',
  styleUrl: './credit-note-bill-row.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CreditNoteBillRowComponent,
      multi: true,
    },
  ],
})
export class CreditNoteBillRowComponent
  implements OnDestroy, OnInit, ControlValueAccessor
{
  @Input({ required: true }) cityTaxMaxRefundablesMap: Record<
    string,
    MaxRefundableBill
  >;

  detailRowOpen = signal<boolean>(false);

  taxRowIsOpen = signal<boolean>(false);

  groupRowIsSelected: boolean = false;

  private subs = new Subscription();

  private ovverrideBillStore = inject(ApiOvverrideBill);

  private fb = inject(FormBuilder);

  onTouched: any = () => {};

  onChange: any = () => {};

  form = this.fb.group<CreditNoteRowGroupControls>({
    groupAmount: this.fb.control<{
      type: 'import' | 'percentage';
      amount: number;
    }>(null),
    groupMaxRefundable: this.fb.control<number>(null),
    groupId: this.fb.control<number>(null),
    groupLabel: this.fb.control<string>(null),
    type: this.fb.control<string>(null),
    rows: this.fb.array<CreditNoteRowForm>([]),
  });

  constructor() {
    effect(() => {
      if (this.form.value.type === 'stay' && !this.detailRowOpen()) {
        this.resetGroupRowByDefault();
      }

      if (this.form.value.type === 'tax' && !this.taxRowIsOpen()) {
        this.resetGroupRowByDefault();
      }
    });
  }

  ngOnInit(): void {
    this.subs.add(
      this.form.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
        this.onChange(value);
      }),
    );

    this.subs.add(
      this.ovverrideBillStore.ovverideRow$.subscribe((updatedAmounts) => {
        if (!updatedAmounts) {
          return;
        }

        this.rowControls.forEach((row) => {
          const { rowId, rowAmount } = row.value;

          const newAmount = updatedAmounts.get(rowId);

          if (newAmount) {
            row.patchValue({
              rowAmount: {
                amount: newAmount,
                type: rowAmount.type,
              },
            });
          }
        });
      }),
    );

    this.subs.add(
      this.form.controls.groupAmount.valueChanges
        .pipe(debounceTime(300))
        .subscribe(({ amount }) => {
          const { groupId } = this.form.value;

          this.ovverrideBillStore.overrideBillRows({ groupId, amount });
        }),
    );

    this.subs.add(
      this.form.controls.rows.valueChanges.subscribe((rows) => {
        if (!rows.some(({ selected }) => selected)) {
          this.groupRowIsSelected = false;
          return;
        }

        this.groupRowIsSelected = true;
      }),
    );
  }

  writeValue(rowGroup: CreditNoteRowGroup): void {
    if (!rowGroup) {
      this.form.patchValue({
        groupAmount: null,
        groupMaxRefundable: null,
        groupId: null,
        groupLabel: null,
        type: null,
        rows: null,
      });
      return;
    }

    const { rows } = rowGroup;

    this.form.patchValue(rowGroup, { emitEvent: false });

    const rowsFormArray = this.form.controls.rows;

    rowsFormArray.clear({ emitEvent: false });

    rows.forEach((row, index, rows) => {
      const rowForm = this.fb.group<CreditNoteRowControls>({
        rowId: this.fb.control(row.rowId),
        rowLabel: this.fb.control(row.rowLabel),
        rowAmount: this.fb.control(row.rowAmount),
        rowQty: this.fb.control(row.rowQty),
        rowMaxRefundable: this.fb.control(row.rowMaxRefundable),
        rowMaxQty: this.fb.control(row.rowMaxQty),
        selected: this.fb.control(row.selected),
      });

      rowsFormArray.push(rowForm, { emitEvent: rows.length === index + 1 });
    });
  }

  updateGroupRowCheck(checked: boolean) {
    this.rowControls.forEach((row, index, rows) =>
      row.patchValue(
        { selected: checked },
        { emitEvent: rows.length === index + 1 },
      ),
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toogleOpeningRowDetail() {
    this.detailRowOpen.update((prev) => !prev);
  }

  toogleCityTaxRowDetail() {
    this.taxRowIsOpen.update((prev) => !prev);
  }

  setSingleRowSelection(selected: boolean, rowControl: CreditNoteRowForm) {
    rowControl.patchValue({
      selected: selected,
    });
  }

  setAllRowCheckbox(selected: boolean) {
    this.rowControls.forEach((rowControl, index, rows) => {
      rowControl.patchValue(
        { selected },
        { emitEvent: rows.length === index + 1 },
      );
    });
  }

  resetGroupRowByDefault() {
    this.form.patchValue(
      {
        groupAmount: {
          type: 'import',
          amount: this.form.value.groupMaxRefundable,
        },
      },
      { emitEvent: false },
    );

    this.rowControls.forEach((rowControl, index, rows) => {
      const { rowMaxRefundable, rowMaxQty } = rowControl.value;

      rowControl.patchValue(
        {
          rowAmount: { type: 'import', amount: rowMaxRefundable },
          rowQty: rowMaxQty,
          selected: this.groupRowIsSelected,
        },
        { emitEvent: rows.length === index + 1 },
      );
    });
  }

  changeRowQty(qty: number, rowControl: CreditNoteRowForm) {
    const { rowMaxRefundable, rowMaxQty, rowAmount } = rowControl.value;

    rowControl.patchValue({
      rowAmount: {
        type: rowAmount.type,
        amount: (rowMaxRefundable / rowMaxQty) * qty,
      },
    });
  }

  isRowSelected = (row: { selected: boolean }) => {
    return row.selected;
  };

  get rowControls() {
    return this.form.controls.rows.controls;
  }

  get rowValues() {
    return this.form.controls.rows.value;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
