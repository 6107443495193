import { Component, inject, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceHeaderComponent } from '../../invoice-header/invoice-header.component';
import {
  InvoiceDetails,
  TypedSimpleChanges,
  CreditNoteRowGroup,
  MaxRefundableBill,
} from '@app/models';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgEveryPipeModule,
  NgSomePipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { CreditNoteRegisterImportNumberComponent } from '../credit-note-register-import-number/credit-note-register-import-number.component';
import { flatMap, sumBy } from 'lodash';
import { CurrencyFormatComponent, SpinModule } from '@app/ui';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { GenerateGroupTotalsPipe } from '../pipes/generate-group-total.pipe';
import { GenerateTotalToCancelPipe } from '../pipes/generate-total-to-cancel.pipe';
import { MaxRefundableWithCityTaxPipe } from '../pipes/max-refundable-with-city-tax.pipe';

import { CreditNoteBillRowComponent } from '../credit-note-bill-row/credit-note-bill-row.component';
import { ApiOvverrideBill } from '../state/api-ovverride-bills.store';
import { KeyByPipe } from '@app/pipes';

@Component({
  selector: 'by-credit-note-register-bills-table',
  standalone: true,
  imports: [
    CommonModule,
    InvoiceHeaderComponent,
    NzTableModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NzInputModule,
    ReactiveFormsModule,
    CreditNoteRegisterImportNumberComponent,
    CurrencyFormatComponent,
    NzPopconfirmModule,
    NzToolTipModule,
    GenerateGroupTotalsPipe,
    GenerateTotalToCancelPipe,
    MaxRefundableWithCityTaxPipe,
    NgEveryPipeModule,
    CreditNoteBillRowComponent,
    NgSomePipeModule,
    SpinModule,
    KeyByPipe,
  ],
  templateUrl: './credit-note-register-bills-table.component.html',
  styleUrl: './credit-note-register-bills-table.component.scss',
})
export class CreditNoteRegisterBillsTableComponent implements OnChanges {
  @Input({ required: true }) invoice: InvoiceDetails;

  @Input({ required: true }) mediasInvoiceLayouts: string;

  private fb = inject(FormBuilder);

  ovverrideBillStore = inject(ApiOvverrideBill);

  groups = this.fb.array<FormControl<CreditNoteRowGroup>>([]);

  cityTaxMaxRefundablesMap: Record<string, MaxRefundableBill> = {};

  ngOnChanges(changes: TypedSimpleChanges<{ invoice: InvoiceDetails }>): void {
    const { invoice } = changes;

    if (invoice && this.invoiceRows?.length) {
      this.invoiceRowsFilteredByEmptyAmounts.forEach((bill) => {
        const {
          bill_override_id,
          max_refundables,
          label_complete,
          production_type_label,
        } = bill;

        if (production_type_label === 'virtual_stamp') {
          return;
        }

        const maxRefundablesSum = sumBy(bill.max_refundables, 'max_refundable');

        const group = this.fb.control<CreditNoteRowGroup>({
          groupAmount: {
            type: 'import',
            amount: maxRefundablesSum,
          },
          groupMaxRefundable: maxRefundablesSum,
          groupId: bill_override_id,
          groupLabel: label_complete,
          type: production_type_label,
          rows: max_refundables
            .filter(({ max_refundable }) => max_refundable)
            .map((rowBill) => {
              if (rowBill.production_type_label === 'tax') {
                this.cityTaxMaxRefundablesMap = {
                  ...this.cityTaxMaxRefundablesMap,
                  [rowBill.id]: rowBill,
                };
              }

              return {
                rowLabel: rowBill.label,
                rowId: rowBill.id,
                rowAmount: { type: 'import', amount: rowBill.max_refundable },
                rowQty: rowBill.qty,
                rowMaxRefundable: rowBill.max_refundable,
                rowMaxQty: rowBill.qty,
                selected: false,
              };
            }),
        });

        this.groups.push(group);
      });
    }
  }

  setAllChecked(checked: boolean) {
    const groups = this.groups.controls.map((group) => {
      const { rows } = group.value;
      return {
        ...group.value,
        rows: rows.map((row) => ({ ...row, selected: checked })),
      };
    });

    this.groups.patchValue(groups);
  }

  get invoiceRows() {
    return this.invoice.bills_list;
  }

  get invoiceRowsFilteredByEmptyAmounts() {
    return this.invoiceRows.filter(({ max_refundables }) =>
      sumBy(max_refundables, 'max_refundable'),
    );
  }

  get groupsControls() {
    return this.groups.controls;
  }

  get groupRowValues() {
    return this.groups.getRawValue();
  }

  get rowsBillValues() {
    return {
      total: sumBy(
        flatMap(this.groupRowValues, 'rows').filter(({ selected }) => selected),
        'rowAmount.amount',
      ),
      rows: flatMap(this.groupRowValues, (group) => {
        return group.rows
          .filter(({ selected }) => selected)
          .map(({ rowId, rowAmount, rowQty }) => ({
            id: rowId,
            override_id: group.groupId,
            price: rowAmount.amount,
            qty: rowQty,
          }));
      }),
    };
  }

  isAllRowsOnGroupAreSelected = (group: { rows: { selected: boolean }[] }) => {
    const { rows } = group;
    return rows.every(({ selected }) => selected);
  };

  isSomeRowOnGroupIsSelected = (group: { rows: { selected: boolean }[] }) => {
    const { rows } = group;
    return rows.some(({ selected }) => selected);
  };
}
