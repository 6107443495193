import { Pipe, PipeTransform } from '@angular/core';
import { InvoiceDetails } from '../../../../models';
import { flatMap, sumBy } from 'lodash';

@Pipe({
  name: 'maxRefundableWithCityTax',
  standalone: true,
})
export class MaxRefundableWithCityTaxPipe implements PipeTransform {
  transform(invoice: InvoiceDetails): number {
    const cityTaxRows = invoice.bills_list.filter(
      ({ production_type_label }) => production_type_label === 'tax',
    );

    if (cityTaxRows) {
      return (
        invoice.maxRefundable +
        sumBy(flatMap(cityTaxRows, 'max_refundables'), 'max_refundable')
      );
    }

    return invoice.maxRefundable;
  }
}
