import { Injectable } from '@angular/core';

import { SdiStatus } from '../../../config/sdi-status.config';
import { Invoice, InvoiceDetails } from '../../../models';

@Injectable({ providedIn: 'root' })
export class InvoiceConditionsService {
  readonly SdiStatus = SdiStatus;

  constructor() {}

  // è un conto passante?
  getIsGeneral(invoice: Partial<Invoice> | InvoiceDetails): boolean {
    return !invoice?.reservation_id && !invoice?.registered;
  }

  // è possibile eliminarla?
  getIsDeletable(invoice: Partial<Invoice> | InvoiceDetails): boolean {
    return !!(
      this.getIsGeneral(invoice) ||
      (invoice?.advance && !invoice.fp_send && !invoice?.status_sdi) ||
      (!invoice?.status_sdi &&
        !invoice.fp_send &&
        invoice?.registered &&
        invoice.type === 'credit_note')
    );
  }

  // è possibile mostrare il tasto per cambiare il paymentStatus?
  getCanChangePaymentStatus(
    invoice: Partial<Invoice> | InvoiceDetails,
  ): boolean {
    return invoice?.registered && !invoice?.advance && invoice?.to_pay > 0;
  }

  // è possibile cambiare lo stato di hidden?
  getCanChangeHiddenStatus(
    invoice: Partial<Invoice> | InvoiceDetails,
  ): boolean {
    return !invoice?.registered && !(invoice as InvoiceDetails)?.temporary;
  }

  // è possibile riaprire il conto
  getCanRestore(invoice: Partial<Invoice> | InvoiceDetails): boolean {
    return (
      !invoice?.linked_documents?.length &&
      ((invoice?.registered &&
        !invoice?.advance &&
        !invoice?.status_sdi &&
        !invoice?.fp_send &&
        invoice?.type !== 'credit_note' &&
        invoice?.document_type_id !== 7) ||
        (!invoice?.registered &&
          invoice?.status_sdi === this.SdiStatus.Rejected &&
          !!invoice?.reservation_id))
    );
  }

  // è possibile generare nota di credito o storno?
  getCanCreateCreditNote(invoice: Partial<Invoice> | InvoiceDetails): boolean {
    return (
      invoice?.registered &&
      (invoice?.type === 'invoice' || invoice?.type === 'receipt') &&
      (!invoice?.status_sdi || invoice?.status_sdi === 3) &&
      !invoice?.all_inclusive_tax_document &&
      invoice.document_type_id !== 5 &&
      !invoice?.document_type_code_rules?.cancellation_not_possible
    );
  }

  // è possibile generare l'annullo dell'autofattura?
  getCanReverseAutoinvoice(
    invoice: Partial<Invoice> | InvoiceDetails,
  ): boolean {
    return (
      invoice?.registered &&
      (!invoice?.status_sdi || invoice?.status_sdi === 3) &&
      !invoice?.all_inclusive_tax_document &&
      invoice.document_type_id === 5 &&
      !invoice?.document_type_code_rules?.cancellation_not_possible
    );
  }

  // è possibile inviare un sollecito
  getCanSendReminder(invoice: Partial<Invoice> | InvoiceDetails) {
    return invoice?.status === 'suspended';
  }

  // è possibile inviare il download dell'xml
  getCanDownloadXml(invoice: Partial<Invoice> | InvoiceDetails) {
    return invoice?.xml_uri;
  }

  // è possibile effettuare un pagamento
  getCanPayInvoice(invoice: Partial<Invoice> | InvoiceDetails) {
    return invoice?.status !== 'paid';
  }

  // è possibile mostrare l'history dei solleciti inviati
  getCanShowSendHistory(invoice: Partial<Invoice> | InvoiceDetails) {
    return (
      invoice?.status === 'suspended' &&
      invoice.send_history &&
      !!invoice.send_history.length
    );
  }

  // è possibile mostrare il formato sdi
  getCanViewSdiFormat(invoice: Partial<Invoice> | InvoiceDetails) {
    return !!(
      (invoice.type === 'invoice' || invoice.type === 'credit_note') &&
      invoice?.status_sdi > 0 &&
      invoice?.xml_uri
    );
  }

  // è possibile mostrare i campi mancanti per la fatturazione
  getCanShowInvoiceMissingFields(invoice: Partial<InvoiceDetails>) {
    return (
      !invoice?.reversal &&
      !invoice?.registered &&
      !invoice?.all_inclusive_tax_document
    );
  }
}
