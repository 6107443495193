<by-page-header
  [titleTemplate]="title"
  [byStyle]="{ 'padding-top': 0, 'padding-bottom': 5 }"
></by-page-header>

<ng-template #title>
  <div class="by-flex by-flex-between">
    <div class="by-w-95 by-flex by-flex-between">
      <span
        >{{ 'invoice' | translate | upperfirst }}
        {{ invoice.number_complete }}</span
      >
      <by-currency-format [currencyValue]="invoice.total"> </by-currency-format>
    </div>

    <i class="fal fa-times pointer by-fs-20" (click)="closeModal()"></i>
  </div>
</ng-template>

<by-credit-note-register-form
  [paymentMethodsGeneric]="paymentMethodsGeneric$ | async"
  [invoice]="invoice"
  [step]="currentStep()"
  [mediasInvoiceLayouts]="
    mediasInvoiceLayouts$ | async | get: [invoice?.layout?.id]
  "
  [isOnlyCityTaxRefund]="isOnlyCityTaxRefund"
>
</by-credit-note-register-form>

<div *nzModalFooter>
  @if (showGenerateCreditNoteButton) {
    <button nz-button nzType="default" (click)="closeModal()">
      {{ 'cancel' | translate | upperfirst }}
    </button>

    @if (isTypeCreditNoteSelected) {
      <button nz-button nzType="primary" (click)="submit()">
        <i class="fal fa-file-invoice-dollar by-pr-5"></i>
        {{ 'create_credit_note' | translate | upperfirst }}
      </button>
    }
  }

  @if (currentStep() === 'bills') {
    <button nz-button nzType="default" (click)="goTo('total')">
      {{ 'back' | translate | upperfirst }}
    </button>

    @if (creditNoteRegisterForm.tableRows.total && isTypeCreditNoteSelected) {
      <button
        nz-button
        [nzLoading]="ovverrideBillStore.loading$ | async"
        nzType="primary"
        (click)="submit()"
      >
        <i class="fal fa-file-invoice-dollar by-pr-5"></i>
        {{ 'create_credit_note' | translate | upperfirst }}
      </button>
    }
  }

  @if (showNextButton) {
    <button nz-button nzType="primary" (click)="goTo('bills')">
      {{ 'next' | translate | upperfirst }}
    </button>
  }
</div>
