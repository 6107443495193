import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

import { DefaultInvoicingReceiverCodes } from '../../../config';
import { SdiStatus } from '../../../config/sdi-status.config';
import { InvoiceDetails, InvoiceType } from '../../../models';
import { TypedSimpleChanges } from '../../../models/types/simple-changes-typed';
import { InvoiceConditionsService } from '../services/invoice-conditions.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgUpperFirstPipeModule } from '@z-trippete/angular-pipes';
import { InvoiceContractorLabelModule } from '@app/pipes/invoice-contractor-type/invoice-contractor-label.module';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'by-invoice-header',
  templateUrl: './invoice-header.component.html',
  styleUrls: ['./invoice-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    InvoiceContractorLabelModule,
    NzGridModule,
    NzToolTipModule,
  ],
})
export class InvoiceHeaderComponent implements OnChanges {
  @Input() invoice: InvoiceDetails;

  @Input() invoiceLayoutLogo: string;

  @Input() destinationMissingFields: string[] = [];

  @Input() senderMissingFields: string[] = [];

  @Input() isEditable = false;

  @Input() isPassCharge = false;

  @Input() invoiceTypes: Record<number, InvoiceType>;

  @Output() changeInvoiceLayout = new EventEmitter();

  @Output() editLabelOrDate = new EventEmitter();

  @Output() changeDestination = new EventEmitter<string>();

  @Output() editDestination = new EventEmitter<'sender' | 'destination'>();

  readonly SdiStatus = SdiStatus;

  defaultItalianReceiverCode = DefaultInvoicingReceiverCodes.Italian;

  defaultNotItalianReceiverCode = DefaultInvoicingReceiverCodes.NotItalian;

  senderTplType: 'layout' | 'customer' = 'layout';

  destinationTplType: 'layout' | 'customer' = 'customer';

  constructor(private invoiceConditions: InvoiceConditionsService) {}

  ngOnChanges(changes: TypedSimpleChanges<{ invoice: InvoiceDetails }>): void {
    const { invoice } = changes;
    if (invoice && this.invoice?.document_type_code_rules) {
      this.setContractorTplType();
    }
  }

  setContractorTplType() {
    this.setSenderTpl();

    this.setDestinationTpl();
  }

  setDestinationTpl() {
    if (!(this.invoice?.document_type_code_rules as any)?.length) {
      this.destinationTplType = 'customer';
    }

    if (this.invoice?.document_type_code_rules?.destination_is_customer) {
      this.destinationTplType = 'customer';

      return;
    }

    if (this.invoice?.document_type_code_rules?.destination_is_layout) {
      this.destinationTplType = 'layout';
    }
  }

  setSenderTpl() {
    if (!(this.invoice?.document_type_code_rules as any)?.length) {
      this.senderTplType = 'layout';
    }

    if (
      this.invoice?.document_type_code_rules?.sender_is_layout &&
      this.invoice?.document_type_code_rules?.destination_is_layout
    ) {
      this.senderTplType = 'customer';

      return;
    }

    if (this.invoice?.document_type_code_rules?.sender_is_layout) {
      this.senderTplType = 'layout';

      return;
    }

    if (this.invoice?.document_type_code_rules?.sender_is_supplier) {
      this.senderTplType = 'customer';
    }
  }

  get invoiceDate(): Date {
    if (this.invoice?.invoice_date) {
      return new Date(this.invoice.invoice_date);
    }

    return new Date();
  }

  get showDestinationMissingFields(): boolean {
    const baseCondition =
      this.destinationMissingFields?.length &&
      this.invoiceConditions.getCanShowInvoiceMissingFields(this.invoice);

    return (
      (baseCondition && !this.isPassCharge) ||
      (baseCondition &&
        this.isPassCharge &&
        this.invoice.document_type_id !== 4)
    );
  }

  get showSenderMissingFields(): boolean {
    return (
      this.senderMissingFields?.length &&
      this.invoiceConditions.getCanShowInvoiceMissingFields(this.invoice)
    );
  }

  get showNumberComplete() {
    return (
      this.invoice?.registered ||
      (!this.invoice?.registered &&
        this.invoice?.status_sdi === this.SdiStatus.Rejected)
    );
  }

  get showEditSenderCustomer() {
    return (
      this.invoice?.document_type_code_rules?.sender_is_supplier &&
      this.invoice.layout.company_id !== this.invoice.sender.company_id
    );
  }

  get isFollowInvoice() {
    return !!this.invoice?.follows_invoice;
  }
}
