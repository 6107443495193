import {
  Component,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { roundToTwoDecimals } from '@app/helpers/round-to-decimals';
import { Subscription } from 'rxjs';

@Component({
  selector: 'by-credit-note-register-import-number',
  standalone: true,
  imports: [
    CommonModule,
    NzInputNumberModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './credit-note-register-import-number.component.html',
  styleUrls: ['./credit-note-register-import-number.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreditNoteRegisterImportNumberComponent),
      multi: true,
    },
  ],
})
export class CreditNoteRegisterImportNumberComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() decimals: number = 2;

  @Input() maxValue: number;

  @Input() disabled: boolean = false;

  private fb = inject(FormBuilder);

  form = this.fb.group({
    type: this.fb.control<'import' | 'percentage'>('import'),
    amount: this.fb.control<number>(0),
  });

  private subs = new Subscription();

  ngOnInit(): void {
    this.subs.add(
      this.form.valueChanges.subscribe((value) => {
        this.onChange(value);
      }),
    );
  }

  writeValue(value: { amount: number; type: 'import' | 'percentage' }): void {
    this.form.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange: (value: {
    amount?: number;
    type?: 'import' | 'percentage';
  }) => void = () => {};

  onTouched: () => void = () => {};

  handleValueChange(newImport: number): void {
    if (this.form.value.type === 'import') {
      this.form.patchValue({ amount: newImport });
    } else {
      const calculatedValue = roundToTwoDecimals(
        (this.maxValue * newImport) / 100,
        3,
      );

      this.form.patchValue({ amount: calculatedValue });
    }
    this.onTouched();
  }

  handleTypeChange(type: 'import' | 'percentage') {
    this.form.patchValue({
      type,
      amount: roundToTwoDecimals(
        this.currentAmount,
        type === 'percentage' ? 3 : 2,
      ),
    });
  }

  get calculatePercentage(): number {
    const amount = this.form.value.amount || 0;

    return roundToTwoDecimals((amount * 100) / this.maxValue, this.decimals);
  }

  get currentAmount() {
    return this.form.value.amount;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
