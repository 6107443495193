import { Pipe, PipeTransform } from '@angular/core';
import { sumBy, flatMap } from 'lodash';
import { CreditNoteRowGroup } from '../../../../models';

@Pipe({
  name: 'generateTotalToCancel',
  standalone: true,
})
export class GenerateTotalToCancelPipe implements PipeTransform {
  transform(groups?: CreditNoteRowGroup[]): number {
    if (!groups?.length) {
      return 0;
    }

    return sumBy(
      flatMap(groups, 'rows').filter(({ selected }) => selected),
      'rowAmount.amount',
    );
  }
}
