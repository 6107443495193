import {
  Component,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgFirstOrDefaultPipeModule,
  NgUpperFirstPipeModule,
} from '@z-trippete/angular-pipes';
import { NzGridModule } from 'ng-zorro-antd/grid';
import {
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { QuestionModule } from '@app/components/question/question.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  CreditNoteVirtualStamp,
  InvoiceDetails,
  PaymentMethod,
  PaymentsCreditNote,
  TypedSimpleChanges,
} from '@app/models';
import { INVOICING_ALLOWED_PAYMENT_METHODS_ID } from '@app/config/invoicing-allowed-payment-methods.config';
import { CreditNoteRegisterSliderComponent } from '../credit-note-register-slider/credit-note-register-slider.component';
import { CreditNoteRegisterBillsTableComponent } from '../credit-note-register-bills-table/credit-note-register-bills-table.component';
import { CurrencyFormatComponent } from '@app/ui';
import { PageHeaderModule } from '@app/components/page-header';
import { RouterModule } from '@angular/router';
import { CreditNotePaymentsTableComponent } from '../credit-note-payments-table/credit-note-payments-table.component';
import { CreditNoteVirtualStampRegisterComponent } from '../credit-note-virtual-stamp-register/credit-note-virtual-stamp-register.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'by-credit-note-register-form',
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    TranslateModule,
    NgUpperFirstPipeModule,
    NzGridModule,
    ReactiveFormsModule,
    FormsModule,
    NzFormModule,
    NzRadioModule,
    QuestionModule,
    NzToolTipModule,
    NzAlertModule,
    NzSelectModule,
    NgFirstOrDefaultPipeModule,
    CreditNoteRegisterSliderComponent,
    CreditNoteRegisterBillsTableComponent,
    CurrencyFormatComponent,
    PageHeaderModule,
    RouterModule,
    CreditNotePaymentsTableComponent,
    CreditNoteVirtualStampRegisterComponent,
  ],
  templateUrl: './credit-note-register-form.component.html',
  styleUrl: './credit-note-register-form.component.scss',
})
export class CreditNoteRegisterFormComponent
  implements OnChanges, OnDestroy, OnInit
{
  @ViewChild(CreditNoteRegisterBillsTableComponent)
  singleBillsTable: CreditNoteRegisterBillsTableComponent;

  @ViewChild(CreditNotePaymentsTableComponent)
  refundedPaymentsTable: CreditNotePaymentsTableComponent;

  @Input({ required: true }) paymentMethodsGeneric: PaymentMethod[];

  @Input({ required: true }) invoice: InvoiceDetails;

  @Input({ required: true }) mediasInvoiceLayouts: string;

  @Input({ required: true }) isOnlyCityTaxRefund: boolean;

  @Input({ required: true }) step: 'total' | 'bills';

  invoicingAllowedPaymentMethodsIds = INVOICING_ALLOWED_PAYMENT_METHODS_ID;

  private fb = inject(FormBuilder);

  private subs = new Subscription();

  form = this.fb.group({
    creditNoteType: this.fb.control<'total' | 'partial'>(null),
    reversalType: this.fb.control<'import' | 'bills'>('import'),
    refundSameTime: this.fb.control<number>(0),
    totalValue: this.fb.control<number>(0),
    chargeAmountAligned: this.fb.control<number>(0),
    payments: this.fb.control<PaymentsCreditNote[]>([]),
    paymentMethodSelected: this.fb.control<number>(0),
    virtualStamp: this.fb.control<CreditNoteVirtualStamp>(null),
  });

  ngOnInit(): void {
    this.subs.add(
      this.form.controls.creditNoteType.valueChanges.subscribe((type) => {
        if (type === 'total') {
          this.form.patchValue(
            { totalValue: +this.invoice.maxRefundable },
            { emitEvent: false },
          );
        }
      }),
    );
  }

  ngOnChanges(
    changes: TypedSimpleChanges<{
      invoice: InvoiceDetails;
      isOnlyCityTaxRefund: boolean;
    }>,
  ): void {
    const { invoice, isOnlyCityTaxRefund } = changes;

    if (invoice && this.invoice) {
      this.form.patchValue({ totalValue: +this.invoice.maxRefundable });

      if (this.hasInvoiceLinkedDocuments) {
        this.form.patchValue({ creditNoteType: 'partial' });
      }

      if (this.creditNotePayments.length) {
        this.generateDefaultPayments();
      }
    }

    if (isOnlyCityTaxRefund && this.isOnlyCityTaxRefund) {
      this.form.patchValue({ reversalType: 'bills' }, { emitEvent: false });
    }
  }

  generateDefaultPayments() {
    const payments = this.creditNotePayments.map(
      ({ id, payment_method, max_refundable, date }) => ({
        id,
        maxAmount: +max_refundable,
        currentAmount: 0,
        label: payment_method,
        date,
      }),
    );

    this.form.controls.payments.setValue(payments);
  }

  resetPayments() {
    this.generateDefaultPayments();

    this.form.patchValue({ refundSameTime: 0 }, { emitEvent: false });
  }

  get showRefundSameTimeQuestion() {
    return this.form.value.refundSameTime && !this.isInvoiceTotalSuspended;
  }

  get creditNoteTypeValue() {
    return this.form.value.creditNoteType;
  }

  get isCreditNotePartial() {
    return this.form.value.creditNoteType === 'partial';
  }

  get isReversalTypeImport() {
    return this.form.value.reversalType === 'import';
  }

  get isReversalTypeBills() {
    return this.form.value.reversalType === 'bills';
  }

  get isTotalStep() {
    return this.step === 'total';
  }

  get formValues() {
    return this.form.getRawValue();
  }

  get tableRows() {
    return this.singleBillsTable.rowsBillValues;
  }

  get hasInvoiceLinkedDocuments() {
    return !!this.invoice.linked_documents.length;
  }

  get creditNotePayments() {
    return this.invoice.payments;
  }

  get invoiceBills() {
    return this.invoice.bills_list;
  }

  get isInvoiceFromReservation() {
    return this.invoice.reservation_id;
  }

  get totalToCancel() {
    return this.form.value.totalValue;
  }

  get totaToCancelSplitTable() {
    return this.singleBillsTable?.rowsBillValues.total || 0;
  }

  get creditNotePartialEmitted() {
    return this.invoice.linked_documents;
  }

  get showSplitPayments() {
    return (
      this.showRefundSameTimeQuestion &&
      this.isUserPaymentMethodSelected &&
      this.creditNotePayments.length > 1 &&
      (this.isCreditNotePartial || this.virtualStampCustomerCharged)
    );
  }

  get isUserPaymentMethodSelected() {
    return this.form.value.paymentMethodSelected === 0;
  }

  get isInvoiceTotalSuspended() {
    return this.invoice.paid === 0;
  }

  get isRefunbledPaymetsNotFilled() {
    return this.refundedPaymentsTable?.isBalanceToCancelNotTotalSelected;
  }

  get virtualStampCustomerCharged() {
    const { virtualStamp } = this.form.value;

    if (virtualStamp?.chargeToCustomer) {
      return virtualStamp.stampAmount;
    }

    return 0;
  }

  get amountPayments() {
    return this.isReversalTypeBills
      ? this.totaToCancelSplitTable - this.virtualStampCustomerCharged
      : this.totalToCancel - this.virtualStampCustomerCharged;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
