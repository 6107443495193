import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { InvoiceContractorLabelPipe } from './invoice-contractor-label.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [InvoiceContractorLabelPipe],
  imports: [CommonModule, TranslateModule],
  exports: [InvoiceContractorLabelPipe],
})
export class InvoiceContractorLabelModule {}
